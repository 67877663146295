import request from './request'

export const _http = {
    //上下账
    dispatchBillUpdown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/company-diff-fund-fictitious-updown',
            data:params
        })
    },
    //获取商家账户信息
    dispatchGetCompanyAccount(params){
        return request({
            method: 'GET',
            url: '/platform/pc/company/getCompanyAccount',
            params:params
        })
    },
    //差异基金调账上账红包账户
    dispatchTransDiffFundToRedActivity(params){
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/transDiffFundToRedActivity',
            data:params
        })
    },
    //红包账单列表
    dispatchGetRedBageList(params){
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/getList',
            data:params
        })
    },
    //导出红包账单列表
    dispatchExport(params){
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/exportExcel',
            data:params,
            responseType: 'blob'
        })
    },
    //红包发放记录
    dispatchSendRedBage(params){
        return request({
            method: 'POST',
            url: '/platform/pc/red/envelope/findRedEnvelopeActivitySendRecordList',
            data:params,
        })
    },
    //导出红包发放记录
    dispatchExportSendRedBadge(params){
        return request({
            method: 'POST',
            url: '/platform/pc/red/envelope/excelRedActivitySendRecord',
            data:params,
            responseType: 'blob'
        })
    },
    //活动列表
    dispatchGetActiveList(params){
        return request({
            method: 'GET',
            url: '/platform/pc/red/envelope/getActivitySelectList',
            params:params,
        })
    },
    //获取商户下拉列表
    dispatchGetKACompanyList(){
        return request({
            method: 'GET',
            url: '/platform/pc/company/getKACompanyList',
        })
    },
    //导入批量任务
    dispatchImport(params){
        return request({
            method: 'POST',
            url: '/platform/pc/batch/account/updown/import',
            data:params
        })
    },
    //上下账导入记录
    dispatchUpDownList(params){
        return request({
            method: 'POST',
            url: '/platform/pc/batch/account/updown/list',
            data:params
        })
    },
    //下载导入数据
    dispatchExcelImport(params){
        return request({
            method: 'POST',
            url: '/platform/pc/batch/account/updown/excel-import',
            data:params,
            responseType: 'blob'
        })
    },
    //上下账下沉记录
    dispatchUIEventUpDownRollList(params){
        return request({
            method: 'GET',
            url: '/platform/pc/batch/account/updown/up-down-roll-down-list',
            params:params,
        })
    },
    //确定上下账
    dispatchConfirm(params){
        return request({
            method: 'POST',
            url: '/platform/pc/batch/account/updown/confirm',
            data:params,
        })
    },
    //重新上下账
    dispatchReConfirm(params){
        return request({
            method: 'GET',
            url: '/platform/pc/batch/account/updown/re-confirm',
           params:params,
        })
    },
    //上下账记录
    dispatchAccountList(params){
        return request({
            method: 'POST',
            url: '/platform/pc/batch/account/updown/up-down-list',
            data:params,
        })
    }
}