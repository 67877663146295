<template>
    <div class="GlobalTable">
        <div class="top-box">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div class="faultBtn">
                    <div :class="release == '00' ? 'active' : 'none'" @click="release = '00'">
                        全部
                        <template v-if="release == '00'">
                            ({{ allNum }})
                        </template>
                    </div>
                    <div :class="release == '01' ? 'active' : 'none'" @click="release = '01'">
                        红包退回
                        <template v-if="release == '01'">
                            ({{ refundNum }})
                        </template>
                    </div>
                </div>
                <el-checkbox v-model="checked" v-if="release == '00'">过滤已退回的</el-checkbox>
                <el-checkbox v-model="checked1" v-if="release == '00'">仅待领取</el-checkbox>
                <el-checkbox v-model="checked2" v-if="release == '00'">仅已领取</el-checkbox>
            </div>
            <div>
                <el-button :loading="downloadLoading" size="small" type="success" icon="el-icon-download"
                    @click="exportExcel">{{
                        release ==
                            '00' ? '导出发放记录' : '导出退回记录' }}</el-button>
            </div>
            <!-- @click="addModel('import')" -->
        </div>
        <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
            :currentPage="page.pageNum" :total="this.page.total" @handleCurrentChange="handleCurrentChange">
            <el-table-column label="红包活动名称" slot="redActivityName" align="center">
                <template slot-scope="{ row }">
                    {{ row.redActivityName || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="所属商户" slot="companyName" align="center">
                <template slot-scope="{ row }">
                    {{ row.companyName || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="所在门店" slot="storeName" align="center">
                <template slot-scope="{ row }">
                    {{ row.storeName || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="领取人员" slot="outMachineId" align="center">
                <template slot-scope="{ row }">
                    {{ row.staffName }} - {{ row.staffMobile }}({{ row.staffType="03" ? "店长" : "店员" }})
                </template>
            </el-table-column>
            <el-table-column label="发放金额" slot="reward" align="center">
                <template slot-scope="{ row }">
                    {{ row.reward || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="顾客成交价(元)" slot="userFinalPrice" align="center">
                <template slot-scope="{ row }">
                    {{ row.userFinalPrice || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="压价金额(元)" slot="cutPrice" align="center">
                <template slot-scope="{ row }">
                    {{ row.cutPrice || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="奖励类型" slot="rewardType" align="center">
                <template slot-scope="{ row }">
                    {{ row.rewardType == "00" ? "用户成交价奖励" : "压价金额奖励" }}
                </template>
            </el-table-column>
            <el-table-column label="奖励等级" slot="rewardLevel" align="center">
                <template slot-scope="{ row }">
                    {{ row.rewardLevel || "- -" }}
                </template>
            </el-table-column>
            <el-table-column label="发放条件" slot="rewardLevelRegion" align="center">
                <template slot-scope="{ row }">
                    {{ row.rewardLevelRegion || "- -" }}
                </template>
            </el-table-column>
            <el-table-column label="发放时间" slot="createTime" align="center">
                <template slot-scope="{ row }">
                    {{ row.createTime || "- -" }}
                </template>
            </el-table-column>
            <el-table-column label="订单号" slot="orderNo" align="center">
                <template slot-scope="{ row }">
                    <div style="color: #0981ff;cursor: pointer;" @click="toOrderDetails(row)" v-if="row.orderNo">
                        <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo || "- -" }}</span>
                    </div>
                    <div v-else>
                        -
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="旧机串号" slot="imei" align="center">
                <template slot-scope="{ row }">
                    {{ row.imei || "- -" }}
                </template>
            </el-table-column>
            <el-table-column label="订单状态" slot="orderState" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.orderState == "00" ? '待确认' : "" }}</span>
                    <span>{{ row.orderState == "01" ? '已确认' : "" }}</span>
                    <span>{{ row.orderState == "02" ? '待支付' : "" }}</span>
                    <span>{{ row.orderState == "03" ? '已绑码' : "" }}</span>
                    <span>{{ row.orderState == "04" ? '已收货' : "" }}</span>
                    <span>{{ row.orderState == "05" ? '降价收货' : "" }}</span>
                    <span>{{ row.orderState == "10" ? '已取消' : "" }}</span>
                    <span>{{ row.orderState == "20" ? '已作废' : "" }}</span>
                    <span>{{ row.orderState == "30" ? '已退回' : "" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="红包状态" slot="collectState" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.collectState == "00" ? '未领取' : "" }}</span>
                    <span>{{ row.collectState == '01' ? '已领取' : "" }}</span>
                    <span>{{ row.collectState == "02" ? "已退回":"" }}</span>
                </template>
            </el-table-column>
            <el-table-column width="150" label="操作" slot="operation" align="center">
                <template slot-scope="{ row }">
                    <el-button type="primary" @click="seeBill(row)">查看账单</el-button>
                </template>
            </el-table-column>
        </GlobalTable>
    </div>
</template>

<script>
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import { guideExcelAction } from "@/utils/common";
import { confirm } from '@/components/common/messagebox'
import { _http } from "../../../utils/request/bill";
// import TabSelect from '../../../components/common/TabSelect.vue';
export default {
    components: {}, //, TabSelect
    name: "Table",
    mixins: [MinXinRequest],
    props: {
        classList: Array,
        oldBrand: Array,
    },
    data() {
        return {
            loading: false,
            hidePriceButLoading: false,
            downloadLoading: false,
            seachDataList: [],
            pageNum: 1,
            tabData: ['待绑定', '已绑定'],
            release: '00',
            dialogVisible: false, // 弹框的出现与否
            dialogTitle: "", // 标题
            tableColumns: [
                // { slotName: "channel" },
                { slotName: "redActivityName" },
                { slotName: "companyName" },
                { slotName: "storeName" },
                { slotName: "outMachineId" },
                { slotName: "reward" },
                { slotName: "userFinalPrice" },
                { slotName: "cutPrice" },
                { slotName: "rewardType" },
                { slotName: "rewardLevel" },
                { slotName: "rewardLevelRegion" },
                { slotName: "createTime" },
                { slotName: "orderNo" },
                { slotName: "imei" },
                { slotName: "orderState" },
                { slotName: "collectState" },
                { slotName: "typeName" },
                { slotName: "machineName" },
                { slotName: "bindUsername" },
                { slotName: "bindTime" },
                { slotName: "operation" }
            ],
            outMachineName: "",
            modelSkuLoading: false,
            modelSkuList: [],
            useOutSkuButLoading: false,
            rowMachineObj: null,
            selectAddSku: {
                optionKeyName: "",
            },
            noMappingList: [],
            addSkuButLoading: false,
            checked: false,
            checked1: false,
            checked2: false,
            allNum: 0,
            refundNum: 0,
        };
    },
    created() {
        // let SeachParams = {
        //     channel: 'fh',
        //     pageNum: 1,
        //     pageSize: 10,
        // }
        // this.$store.commit('tagsView/SEACH_PARAMS', SeachParams)
        this.handleCurrentChange()
    },
    computed: {
        seachTotal() {
            return this.$store.state.tagsView.seachTotal;
        },
        SeachParams() {
            return this.$store.state.tagsView.SeachParams;
        }
    },
    watch: {
        SeachParams() {
            this.pageNum = 1
            this.handleCurrentChange();
        },
        release(val) {
            if (val == '00') {
                this.checked1 = false
                this.checked1 = false,
                    this.checked2 = false
            }
            this.$emit("tabchange", val)
            this.pageNum = 1
            this.handleCurrentChange()
        },
        checked(val) {
            if (val) {
                this.checked1 = false,
                    this.checked2 = false
                this.pageNum = 1
                this.handleCurrentChange()
            }

        },
        checked1(val) {
            if (val) {
                this.checked = false,
                    this.checked2 = false
                this.pageNum = 1
                this.handleCurrentChange()
            }

        },
        checked2(val) {
            if (val) {
                this.checked1 = false,
                    this.checked = false
                this.pageNum = 1
                this.handleCurrentChange()
            }

        }
    },
    methods: {
        // 表格切换页面
        handleCurrentChange(val) {
            if (val) {
                this.pageNum = val
                this.page.pageNum = val;
            } else {
                this.page.pageNum = 1;
            }
            this.loading = true
            const baseRequest = {
                queryType: this.release,
                pageNum: this.page.pageNum,
                pageSize: this.SeachParams.pageSize,
                companyId: this.SeachParams.companyId,
                redActivityId: this.SeachParams.redActivityId,
                storeId: this.SeachParams.storeId,
                staffMobile: this.SeachParams.staffMobile,
                startTime: this.SeachParams.startTime,
                endTime: this.SeachParams.endTime,
                isFilterRefund: this.checked,
                isWaitCollect: this.checked1,
                isAlreadyCollect: this.checked2,
                orderNo:this.SeachParams.orderNo
            };
            console.log(baseRequest, 987)
            this.$emit("handleCurrentChange", val);
            this.$store.commit("tagsView/POST_TOTAL", 0)
            _http.dispatchSendRedBage(baseRequest).then(res => {
                if (res.code === 1) {
                    this.seachDataList = res.data.ipage.records;
                    this.page.pageNum = res.data.ipage.current;
                    this.page.total = res.data.ipage.total;
                    this.allNum = res.data.allNum
                    this.refundNum = res.data.refundNum
                    this.$store.commit("tagsView/POST_TOTAL", res.data.ipage.total);
                    this.$store.commit("tagsView/POST_RESIDENT", this.seachDataList);
                }
                this.loading = false
            }).finally(() => {
                if (typeof this.SeachParams.disuseLoding === 'function') {
                    this.SeachParams.disuseLoding();
                }
            });
        },
        exportExcel() {
            this.downloadLoading = true
            const baseRequest = {
                queryType: this.release,
                pageNum: this.page.pageNum,
                pageSize: this.SeachParams.pageSize,
                companyId: this.SeachParams.companyId,
                redActivityId: this.SeachParams.redActivityId,
                storeId: this.SeachParams.storeId,
                staffMobile: this.SeachParams.staffMobile,
                startTime: this.SeachParams.startTime,
                endTime: this.SeachParams.endTime,
                isFilterRefund: this.checked,
                isWaitCollect: this.checked1,
                isAlreadyCollect: this.checked2,
                orderNo:this.SeachParams.orderNo,
            };
            _http.dispatchExportSendRedBadge(baseRequest).then((res) => {
                const content = res;
                const blob = new Blob([content]);
                let reader = new FileReader(); // 创建读取文件对象
                reader.addEventListener("loadend", () => {
                    try {
                        let data = JSON.parse(reader.result);
                        if (data.code === 0) {
                            this.$message.error(data.msg || "服务异常");
                        }
                    } catch (err) {
                        const fileName = "红包发放记录.xlsx"
                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const elink = document.createElement("a");
                            elink.download = fileName;
                            elink.style.display = "none";
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }
                });
                reader.readAsText(blob, "utf-8");
                this.downloadLoading = false
            }).catch(err => {
                console.log(err, 9999)
                console.log("97899999");
                this.downloadLoading = false
            });
        },
        //查看账单
        seeBill(row) {
            const { href } = this.$router.resolve({
                path: "/platformOperation/redbageAccount",
                query: {
                    companyId: row.companyId
                },
            });
            window.open(href, "_blank");
        },
        // 订单详情
        toOrderDetails(row) {
            this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
        },
    }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.faultBtn {
    width: 280px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;
    margin-right: 10px;

    .active {
        text-align: center;
        color: white;
        width: 50%;
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        background: #0981ff;
        border-radius: 20px;
    }

    .none {
        text-align: center;
        color: #333;
        width: 50%;
        height: 14px;
        font-size: 14px;
        font-family: FZLanTingHei-M-GBK;
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        border-radius: 20px;
    }
}

.top-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.link {
    text-decoration: underline;
    cursor: pointer;

    &.primary {
        color: #0981ff;
    }

    &.danger {
        color: #ff687b;
    }
}
</style>